import { template as template_520d9cdb765143e19d5ca383315a400c } from "@ember/template-compiler";
const FKText = template_520d9cdb765143e19d5ca383315a400c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
