import { template as template_1705c1992a2d4472a476056f4f71b84d } from "@ember/template-compiler";
const SidebarSectionMessage = template_1705c1992a2d4472a476056f4f71b84d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
