import { template as template_9a7c225a9ec04a348bf5afad618015d8 } from "@ember/template-compiler";
const WelcomeHeader = template_9a7c225a9ec04a348bf5afad618015d8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
